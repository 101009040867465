<template>
  <div class="class-list">
    <GlobalInfoBar
      title="线上支付申请"
      content="管理门店商家提交的线上支付申请，申请通过审核后门店商家则可邀请回收商家使用在线付款功能"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From />
      </div>
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {};
  },
  created() {
    this.$store.commit('tagsView/ROUTER_PATH', this.$route.path )
  },
  methods: {},
};
</script>

<style>
</style>