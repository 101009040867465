<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="100"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label="商家名称"
        slot="companyName"
        style="margin:0"
        label-width="80px"
      >
        <el-input
          v-model="companyName"
          clearable
          placeholder="请输入商家名称查询"
        ></el-input>
      </el-form-item>
      <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addOneMenu"
          >添加支付申请</el-button
        >
      </div>
    </GlobalForm>

    <el-dialog
      title="创建支付申请"
      :visible.sync="Addpartnershow"
      :close-on-click-modal="false"
      width="800px"
      @closed="Addpartnerclose"
    >
      <div
        class="addparto"
        style="display: flex;flex-direction: row;align-items: center;"
      >
        <img
          style="width:14px;height:14px;margin-right:5px;"
          src="../../../assets/images/ico_ts_red (2).png"
          alt=""
        />说明: 输入门店商名称进行模糊查询，选中门店商家后点击确认完成添加
      </div>
      <el-form
        :inline="true"
        class="demo-form-inline"
        size="small"
        style="margin-top: 13px"
      >
        <el-form-item label="门店商家">
          <el-input
            v-model="username"
            clearable
            placeholder="请输入门店商名称查询"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns2"
        :data="this.tableData"
        :currentPage="page2.pageNum"
        :total="page2.total"
        :pageSize="5"
        :isIndex="false"
        @handleCurrentChange="handleCurrentChange2"
      >
        <el-table-column label="单选" width="50" slot="rio" align="center">
          <template scope="scope">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                :label="scope.row.id"
                @change="checkBoxChange(scope.row.id)"
              ></el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </GlobalTable>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            username = '';
            Addpartnershow = false;
            checkList = [];
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="btnLoading"
          @click.native="Addpartitle"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Form",
  data() {
    return {
      tableData: [],
      page2: {
        pageSize: 5,
        total: 0,
        pageNum: 1,
      },
      btnLoading: false,
      username: "",
      tableColumns2: [
        // { label: "申请商家", prop: "companyName" },
        { slotName: "rio" },
        { label: "门店商名称", prop: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "电话", prop: "phone" },
        { label: "地址", prop: "address" },
        { label: "入驻时间", prop: "createTime" },
        // { slotName: "Img" },
        { slotName: "auditStatus" },
      ],
      checkList: [],
      Addpartnershow: false,
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      companyName: "",
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "申请时间:",
          lableWidth: "80px",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          clearable:true,
        },
        { slotName: "companyName" },
        {
          key: "phone",
          isNumber: true,
          type: "input",
          lableWidth: "80px",
          labelName: "联系电话:",
          placeholder: "请输入联系人电话查询",
        },
        {
          key: "isEnable",
          type: "select",
          labelName: "支付开启状态",
          lableWidth: "110px",
          placeholder: "请选择状态",
          option: [
            {
              value: "01",
              label: "启用",
            },
            {
              value: "00",
              label: "禁用",
            },
          ],
        },
        {
          key: "channel",
          type: "select",
          lableWidth: "80px",
          labelName: "申请渠道",
          placeholder: "请选择申请渠道",
          option: [
            { label: "全部", value: "" },
            { label: "后台", value: "01" },
            { label: "前端", value: "02" },
          ],
        },
        { slot: "AddSlot" },
      ],
      formRules: {
        phone: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.companyName = this.$route.query.companyName || "";
    console.log(this.$route.query.companyName);
    this.handleConfirm(this.initData);
  },
  mounted() {},
  methods: {
    Addpartitle() {
      if (!this.checkList.length) {
        this.$message.error("请选择门店商家！");
        return;
      }
      let params = { companyId: this.checkList[0] };
      this.btnLoading = true;
      _api.applyapply(params).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: res.mag || "操作成功！",
            type: "success",
          });
          this.Addpartnershow = false;
          this.handleConfirm();
        } else {
          this.$message.error(res.msg || "操作失败！");
        }
        this.btnLoading = false;
      }).catch(()=>{
        this.btnLoading = false;
      });
    },

    Addpartnerclose() {
      this.username = "";
      this.Addpartnershow = false;
      this.checkList = [];
    },
    onSubmit() {
      let params = { pageNum: 1, pageSize: 5, companyName: this.username };
      _api.getcompanylist(params).then((res) => {
        this.tableData = res.data.records;
        this.page2.pageSize = res.data.size;
        this.page2.total = res.data.total;
        this.page2.pageNum = res.data.current;
      });
    },
    handleCurrentChange2(val) {
      if (val) {
        this.page2.pageNum = val;
      } else {
        this.page2.pageNum = 1;
      }

      let params = {
        pageNum: this.page2.pageNum,
        pageSize: 5,
        companyName: this.username,
      };
      _api.getcompanylist(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let arr = res.data.records;
          arr.forEach((item) => {
            item.checked = false;
          });
          this.tableData = arr;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
        }
      });
    },
    checkBoxChange(val) {
      // 控制 单选
      if (this.checkList.indexOf(val) > -1) {
        this.checkList = [];
        this.checkList.push(val);
      }
      console.log(this.checkList);
    },

    addOneMenu() {
      this.Addpartnershow = true;
      let params = { pageNum: 1, pageSize: 5 };
      _api.getcompanylist(params).then((res) => {
        this.tableData = res.data.records;
        this.page2.pageSize = res.data.size;
        this.page2.total = res.data.total;
        this.page2.pageNum = res.data.current;
      });
    },
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (this.companyName) {
        SeachParams = {
          companyName: this.companyName,
          pageNum: 1,
          pageSize: 10,
        };
      }
      if (data) {
        SeachParams = {
          phone: data.phone || "",
          companyName: this.companyName || data.companyName || "",
          isEnable: data.isEnable || "",
          channel: data.channel || "",
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams.disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
/deep/.el-checkbox-group {
  // 隐藏 1
  .el-checkbox {
    .el-checkbox__label {
      display: none;
    }
  }
}
.addparto {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #ff8080;
}
</style>              
